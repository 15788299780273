<template>
  <div>
    <div
      class="text-center"
      v-if="isLoading"
    >
      <v-progress-circular
        indeterminate
        size="24"
      ></v-progress-circular>
    </div>
    <div v-if="!isLoading">
      <v-card outlined>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <CmsSetting
                  :header.sync="detail.header"
                  :title.sync="detail.title"
                  :url.sync="detail.url"
                  :meta_description.sync="detail.meta_description"
                  :meta_keywords.sync="detail.meta_keywords"
                  :content.sync="detail.content"
                  :published-at.sync="detail.published_at"
                  :is-published.sync="detail.is_published"
                />
              </v-col>
            </v-row>
          </v-container>
          <div class="text-right save-block d-flex justify-end">
            <CmsFilter
              class="pr-2"
              v-model="detail.compilation"
              :loading="loading"
              :error-messages="checkErrors('detail.compilation')"
            />
            <v-btn
              class="primary mr-2"
              small
              :loading="isSavingData"
              @click="saveDetail(false)"
            > Сохранить
            </v-btn>
            <v-btn
              small
              @click.stop="confirmDelete = true"
            > Удалить страницу
              <v-icon>mdi-</v-icon>
            </v-btn>
            <v-dialog
              v-model="confirmDelete"
              max-width="390"
            >
              <v-card
                outlined
                class="pa-5"
              >
                <v-card-title class="headline">Подтвердите удаление</v-card-title>
                <v-card-text>
                  Вы действительно хотите удалить эту страницу?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    @click="confirmDelete = false"
                  >
                    Отменить
                  </v-btn>
                  <v-btn
                    class="primary"
                    small
                    @click="deleteDetail"
                    :loading="checkingDelete"
                  >
                    Да, удалить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>

import detail from '@/components/mixins/detail'
import { required, maxLength } from 'vuelidate/lib/validators'
import validationErrors from '@/components/mixins/validationErrors'
import CmsSetting from '@/components/views/admin/cms/Detail/CmsSetting'
import CmsFilter from '@/components/views/admin/cms/Detail/CmsFilter'
import { baseUrl } from '@/config'

export default {
  mixins: [detail, validationErrors],
  components: { CmsSetting, CmsFilter },
  name: 'Detail',
  data () {
    return {
      confirmDelete: false,
      componentCategory: 'cms-page'
    }
  },
  watch: {
    'detail.is_published': {
      handler () {
        if (this.detail && this.detail.is_published) {
          this.$v.$touch()
        } else {
          this.$v.$reset()
        }
      },
      immediate: true
    }
  },
  provide () {
    return {
      $v: this.$v
    }
  },
  validations: {
    detail: {
      header: {
        required,
        maxLength: maxLength(64)
      },
      url: {
        required,
        maxLength: maxLength(64),
        isUniqueUrl (value) {
          let result = true
          if (this.$v.detail.url.$dirty) {
            result = this.$store.dispatch('auth/isUniqueUrl', {
              data: {
                url: value,
                id: this.detail.id
              }
            })
          }
          return result
        }
      },
      title: {
        required,
        maxLength: maxLength(70)
      },
      compilation: {
        required
      },
      meta_description: {
        required,
        maxLength: maxLength(160)
      },
      meta_keywords: {
        maxLength: maxLength(160)
      }
    }
  },
  computed: {
    trueUrl () {
      return baseUrl + this.url
    }
  },
  methods: {
    async hasErrors () {
      let canSave = false
      this.$v.$touch()
      await this.checkPending()
      if (this.$v.$anyError) {
        canSave = 'Введите корректные данные'
      } else {
        this.$v.$reset()
      }
      return canSave
    },
    handleDelete () {
      this.confirmDelete = false
      this.$emit('delete')
    }
  }
}
</script>

<style scoped>
  .on-air {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #999999;
  }
</style>
